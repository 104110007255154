import React, { useEffect } from "react";
import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header2/Header";
import AboutUsWrapper from "./style";
import { Helmet } from "react-helmet";
import SubscribeSticky from "../../components/common/SubscribeSticky/SubscribeSticky";
import { getOrgSchema } from "../../helper/schemaData";

const AboutUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <AboutUsWrapper>
      <Helmet defer={false}>
        <title>
          About The Established - A Digital Publishing Platform | The ESTD
        </title>
        <meta
          name="description"
          content="The Established is a space to engage with a democratic and inclusive community. We create and curate culturally relevant content that has its pulse on the worlds of fashion, art, beauty, tech, auto and culture."
        />
      </Helmet>
      {/* Google Tag Manager (gtag.js) */}
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16472986144"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16472986144');
      `}
        </script>

        <script type="application/ld+json">
          {JSON.stringify(getOrgSchema())}
        </script>
      </Helmet>
      <Header />
      <div className="aboutus-main-div">
        <div className="row ptp-8 ma-0 about-us-background">
          <div className="col-lg-5 col-xl-5 col-md-5 col-sm-12 about-us">
            ABOUT US
          </div>
          <div className="col-lg-7 col-xl-7 col-md-7 col-sm-12 Biotif-Light about-us-description">
            <div className="Biotif-Light">
              The Established started off as a casual dinner table conversation
              between the founders who wanted to bring back thought-provoking
              conversations with community at its core. Many zoom calls, late
              nights and excel sheets later, The Established—a digital platform
              at the forefront of a modern landscape, shaped by a community of{" "}
              <span className="Biotif-SemiBold">conscious, authentic </span>
              and{" "}
              <span className="Biotif-SemiBold">
                {" "}
                well-informed, progressive{" "}
              </span>
              consumers—was launched.
            </div>
            <div className="Biotif-Light">
              Built together with a bold character,{" "}
              <span className="Biotif-SemiBold">culturally diverse </span>
              contributors and{" "}
              <span className="Biotif-SemiBold">impactful</span> collaborations,
              The Established is a space to engage with a{" "}
              <span className="Biotif-SemiBold">democratic</span> and{" "}
              <span className="Biotif-SemiBold">inclusive community. </span>
              We create and curate culturally relevant content that has its
              pulse on the worlds of fashion, art, beauty, tech, auto and
              culture.
            </div>
            <div className="Biotif-Light">
              We’re not looking at our audience through the lens of a consumer,
              but rather, an audience that reflects members of society who are
              active, engaged, have both an interest in and responsibility
              toward the world they live in. If you have a definitive point of
              view and a unique voice, you’ve come to the right place.
            </div>
            <div className="Biotif-Light">
              <span className="Biotif-SemiBold">Work with us</span>:{" "}
              <a
                className="Biotif-Light"
                onClick={() =>
                  (window.location = "mailto:careers@theestablished.com")
                }
              >
                careers@theestablished.com
              </a>
            </div>
          </div>
        </div>
        <div className="row ptp-8 ma-0 about-us-background about-us-web-view">
          <div className="col-lg-5 col-xl-5 col-md-5 col-sm-12 about-us">
            TEAM
          </div>
          <div className="col-lg-3 col-xl-3 col-md-3 col-sm-12 Biotif-Regular">
            <div className="text-white">
              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">Akash Sheth</div>
                <div className="member-designation Biotif-Light">FOUNDER</div>
              </div>

              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">
                  Akshita Phoolka
                </div>
                <div className="member-designation Biotif-Light">
                  CREATIVE LEAD
                </div>
              </div>

              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">
                  Karishma Kuenzang
                </div>
                <div className="member-designation Biotif-Light">
                  CULTURE EDITOR
                </div>
              </div>

              {/* <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">Barry Rodgers</div>
                <div className="member-designation Biotif-Light">
                  MANAGING EDITOR
                </div>
              </div> */}
              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">Himani Raina</div>
                <div className="member-designation Biotif-Light">
                  ART DIRECTOR
                </div>
              </div>
              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">Medha Nag</div>
                <div className="member-designation Biotif-Light">
                  SOCIAL MEDIA MANAGER
                </div>
              </div>
              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">
                  Sparsh Kataria
                </div>
                <div className="member-designation Biotif-Light">
                  HEAD OF BUSINESS DEVELOPMENT
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-xl-3 col-md-3 col-sm-12 ">
            <div className="text-white">
              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">
                  Shruti Thacker
                </div>
                <div className="member-designation Biotif-Light">
                  CO-FOUNDER & EDITORIAL DIRECTOR
                </div>
              </div>

              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">
                  Khorshed Deboo
                </div>
                <div className="member-designation Biotif-Light">
                  COPY EDITOR
                </div>
              </div>

              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">Ria Bhatia</div>
                <div className="member-designation Biotif-Light">
                  ASSOCIATE BEAUTY EDITOR
                </div>
              </div>
              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">
                  Nidhi Govindan
                </div>
                <div className="member-designation Biotif-Light">
                  GRAPHIC DESIGNER
                </div>
              </div>
              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">Saurabh Gupta</div>
                <div className="member-designation Biotif-Light">
                  CHIEF TECHNOLOGY OFFICER
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-us-mobile-view">
          <div className="about-us">TEAM</div>
          <div className="Biotif-Regular">
            <div className="text-white">
              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">Akash Sheth</div>
                <div className="member-designation Biotif-Light">FOUNDER</div>
              </div>

              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">
                  Shruti Thacker
                </div>
                <div className="member-designation Biotif-Light">
                  CO-FOUNDER & EDITORIAL DIRECTOR
                </div>
              </div>

              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">
                  Akshita Phoolka
                </div>
                <div className="member-designation Biotif-Light">
                  CREATIVE LEAD
                </div>
              </div>

              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">
                  Khorshed Deboo
                </div>
                <div className="member-designation Biotif-Light">
                  COPY EDITOR
                </div>
              </div>

              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">
                  Karishma Kuenzang
                </div>
                <div className="member-designation Biotif-Light">
                  CULTURE EDITOR
                </div>
              </div>

              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">Ria Bhatia</div>
                <div className="member-designation Biotif-Light">
                  ASSOCIATE BEAUTY EDITOR
                </div>
              </div>

              {/* <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">Barry Rodgers</div>
                <div className="member-designation Biotif-Light">
                  MANAGING EDITOR
                </div>
              </div> */}
              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">Himani Raina</div>
                <div className="member-designation Biotif-Light">
                  ART DIRECTOR
                </div>
              </div>

              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">
                  Nidhi Govindan
                </div>
                <div className="member-designation Biotif-Light">
                  GRAPHIC DESIGNER
                </div>
              </div>

              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">Medha Nag</div>
                <div className="member-designation Biotif-Light">
                  SOCIAL MEDIA MANAGER
                </div>
              </div>

              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">Saurabh Gupta</div>
                <div className="member-designation Biotif-Light">
                  CHIEF TECHNOLOGY OFFICER
                </div>
              </div>

              <div className="pbp-10 Biotif-Regular">
                <div className="team-member Biotif-SemiBold">
                  Sparsh Kataria
                </div>
                <div className="member-designation Biotif-Light">
                  HEAD OF BUSINESS DEVELOPMENT
                </div>
              </div>
            </div>
          </div>
          {/* <div className="text-white"></div> */}
        </div>
        <div className="row ptp-8 ma-0 about-us-background">
          <div className="col-lg-5 col-xl-5 col-md-5 col-sm-12 about-us">
            CONTRIBUTORS
          </div>
          <div className="col-lg-7 col-xl-7 col-md-7 col-sm-12 Biotif-Light about-us-description">
            <div className="Biotif-Light">
              Aatish Nath, Akshita M Bhanj Deo, Akshita Sekhri, Alex Sanchez,
              Amit Gurbaxani, Anamika Butalia, Anil Relia, Aninda Sardar,
              Anindita Ghose, Anindiya Dutta, Anisha Dossa, Anjan Sachar, Ankur
              Pathak, Anshuka Parwani, Arman Khan, Aydha Mehnaz, Christopher
              D’souza, Deepali Singh, Devika Chowdhary, Dhiram Shah, Diipa
              Buller-Khosla, Ekta Rajani, Farah Ahamed, Gouri Shah, Hanadi M
              Habib, Hasina Khatib, Jaison Lewis, Kainaz Contractor, Kalyani
              Chawla, Kimi Dangor, Kriti Saraswat, Malini Banerjee, Masoom
              Minawala, Mona Varonica Campbell, Naina Hiranandani, Natasha
              Khuranna, Neharika Manjani, Nikhila Palat, Nolan Lewis, Onir,
              Osman Abdul Razak, Pablo Chaterji, Phorum Pandya, Praachi
              Raniwala, Prachi Joshi, Pranav Bhardwaj, Priyanka Khanna, Ria
              Bhatia, Rituparna Som, Rohan Hande, Ronny Sen, Roshni Bajal
              Sanghvi, Rutu Mody-Kamdar, Sahar Masoor, Sakshi Sindwani, Saloni
              Dhruv, Samriddhi Raj, Sandip Roy, Sanjukta Sharma, Sarina
              Siddhanti, Shabana Vahi, Shaikh Ayaz, Shweta Malhotra, Sneha
              Mankani, Suhair Khan, Tanya Mehta, Tito, Vasudha Rai, Yashna
              Chopra, Zahra Amiruddin.
            </div>
          </div>
        </div>
      </div>
      <SubscribeSticky />
      <Footer />
    </AboutUsWrapper>
  );
};

export default AboutUs;
